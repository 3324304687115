
export default {
  name: 'BaseBottomBar',
  props: {
    bottomBarData: {
      type: Object,
      default: () => ({}),
    },
  },
}
